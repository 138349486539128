import React, { useState, useCallback, useContext } from 'react';
import { Row, Col, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams, Route, Switch, NavLink } from 'react-router-dom';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import MassiveUploadProject from 'components/form/upload/massiveUploadProject';
import ProjectContractModal from 'components/contracts/projectContractModal';
import ProjectInvoiceModal from 'components/invoice/projectInvoiceModal';
import ProjectTimesheetModal from 'components/timesheet/projectTimesheetModal';
import ProjectPaymentModal from 'components/payments/projectPaymentModal';
import { columnCentered, columnToRight } from '@lib/helpers/agGridHelpers';
import { dateFormatter, formatToDecimals } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
//import Unauthorized from 'components/Unauthorized';
import { months } from '@lib/helpers/calendar';
import IndustrialAccounts from 'components/atomic/organisms/IndustrialAccounts/IndustrialAccounts';
//import IndustrialAccounts from 'components/industrialaccounts/IndustrialAccounts';
import GenerateTemplatesSection from 'components/projectGenerateTemplates/GenerateTemplatesSection';

const Execution = ({ project }) => {
  const { isOe, userRole } = useContext(LevelContext);
  const { projectId, tab, submenu, subtab } = useParams();
  const [sbShow, setSbShow] = useState(<></>);
  const [errorsContracts, setErrorsContracts] = useState([]);
  const [errorsInvoices, setErrorsInvoices] = useState([]);
  const [errorsPayments, setErrorsPayments] = useState([]);
  const [errorsTimesheets, setErrorsTimesheets] = useState([]);
  //const [contracts, setContracts] = useState([])
  const [, /* refresh */ setRefresh] = useState(false);
  const [activeKey, setActiveKey] = useState(subtab ?? 'contracts');

  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (error) {
      return false;
    }
  };

  /* const poCellRenderer = (params) => {
    return params.value ? translate('common.yes', 'Sì') : translate('common.no', 'No')
  }; */

  const CustomTooltipRenderer = (params) => {
    //console.log('params:', params);

    let message = '';

    if (params.type === 'invoices') {
      message = 'Fatture';
    } else if (params.type === 'invoicePayment') {
      message = 'Pagamenti';
    } else if (params.type === 'invoiceReceipt') {
      message = 'Quietanze';
    }

    return (
      <div className='tooltipInfoBox' style={{ width: 'auto' }}>
        <span>{message}</span>
      </div>
    );
  };

  const logsCellRenderer = (params) => {
    if (params.value) {
      const isValid = isValidJSON(params.value);
      if (isValid) {
        const JSONerrors = JSON.parse(params.value);
        const arrayErrors = Object.values(JSONerrors);
        return arrayErrors.map((error, index) => (
          <p key={index} style={{ color: 'red' }}>
            {Object.values(error)[0]}
          </p>
        ));
      } else {
        return <span>{params.value}</span>;
      }
    } else {
      return null;
    }
  };

  const makeSemaphores = (data) => {
    //console.log('data', data);
    const stoplightColor = {
      red: '#C94723',
      yellow: '#D4BE31',
      green: '#689689',
      white: '#ffffff',
      gray: '#C4C4C4', grey: '#C4C4C4',
      green_white: '#689689',
    };

    let circleStyle;
    let message = '';

    if (data) {
      if (data.color === 'green_white') {
         circleStyle = {
            display: 'inherit',
            width: '23px',
            height: '23px',
            border: '4px solid #689689',
            borderRadius: '50%',
            padding: '0px',
            background: 'linear-gradient(to right, #689689 50%, transparent 0)',
            boxSizing: 'border-box',
         };

         message = 'Conforme - sanata';
      } else {
         circleStyle = {
            display: 'inherit',
            width: '1.2em',
            height: '1.2em',
            borderRadius: '2em',
            MozBorderRadius: '2em',
            WebkitBorderRadius: '2em',
            backgroundColor: stoplightColor[data.color],
            border: `2px solid ${stoplightColor[data.color]}`,
         };

         switch (data.color) {
            case 'white':
               message = 'Da lavorare';
               break;
            case 'grey':
            case 'gray':
               message = 'In lavorazione';
               break;
            case 'red':
               message = 'Non conforme';
               break;
            case 'yellow':
               message = 'Incompleta';
               break;
            case 'green':
               message = 'Conforme';
               break;
            default:
               break;
         }
      }
    } else {
      circleStyle = {
         display: 'inherit',
         width: '1.2em',
         height: '1.2em',
         borderRadius: '2em',
         MozBorderRadius: '2em',
         WebkitBorderRadius: '2em',
         backgroundColor: '#ffffff',
         border: `2px solid ${'#C4C4C4'}`,
      };

      message = 'Da lavorare';
    }

    return (
      <Row className='align-items-center justify-content-center h-100'>
        <Col className='col-auto'>
          <OverlayTrigger placement='top' overlay={<Tooltip>{message}</Tooltip>}>
            <i style={{ ...circleStyle }} />
          </OverlayTrigger>
        </Col>
      </Row>
    );
  };

  const ChecklistCellRenderer = useCallback((params) => {
    //console.log('params', params.type, params);
    if (params.type === 'invoicePayment') {
      return makeSemaphores(params.data.invoicePayment?.checkliststatus);
    } else if (params.type === 'invoiceReceipt') {
      return makeSemaphores(params.data.invoiceReceipt?.checkliststatus);
    } else {
      return makeSemaphores(params.value);
    }
  }, []);

  const LogCellRenderer = useCallback((params) => {
    const red = '#C94723';
    // const yellow = '#D4BE31';
    const green = '#689689';

    const legendStyles = {
      bullet: {
        display: 'inherit',
        width: '1.2em',
        height: '1.2em',
        borderRadius: '2em',
      },
      label: {
        display: 'inline-block',
        width: '50%',
        lineHeight: '2.5em',
      },
    };

    if (params.value) {
      return (
        <Row className='align-items-center justify-content-center h-100'>
          <Col className='col-auto'>
            <i
              style={{
                ...legendStyles.bullet,
                backgroundColor: params.value === 'OK' ? green : red,
                textAlign: 'left',
              }}
            />
          </Col>
        </Row>
      );
    }
  }, []);

  const contractColumn = [
    {
      headerName: 'N. documento',
      field: 'number',
      filter: 'agTextColumnFilter',
    },
    /* {
          headerName: 'N. Contratto Quadro',
          field: '',
          filter: 'agTextColumnFilter',
       }, */
    {
      headerName: 'Data',
      field: 'date',
      type: 'rightAligned',
      // cellRenderer: poCellRenderer,
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      ...columnCentered(),
      //minWidth: 100,
    },

    {
      headerName: 'Codice Fornitore',
      field: 'supplierCode',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Fornitore',
      field: 'supplierName',
      filter: 'agTextColumnFilter',
    },
    // {
    //   headerName: 'Importo €',
    //   type: 'rightAligned',
    //   field: 'baseAmount',
    //   valueGetter: (params) => {
    //     return formatToDecimals(params.data.baseAmount);
    //   },
    //   filter: 'agTextColumnFilter',
    // },

    {
      headerName: '',
      field: 'checkliststatus',
      filter: 'agTextColumnFilter',
      cellRenderer: ChecklistCellRenderer,
      maxWidth: 70,
      cellStyle: { display: 'flex', justifyContent: 'center' },
      pinned: 'right',
    },
    {
      headerName: '',
      pinned: 'right',
      minWidth: 70,
      maxWidth: 70,
      cellStyle: { textAlign: 'center', padding: 0, justifyContent: 'center' },
      cellRenderer: ProjectContractModal,
      cellRendererParams: {
        setRefresh: setRefresh,
      },
    },
  ];
  const contractStageColumn = [
    {
      headerName: 'CUP',
      field: 'projectCup',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'CIG',
      field: 'tenderCig',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
         const value = params.data.tenderCig.startsWith('AFFIDAMENTO_' || 'SPESEPERSONALE_') ? 'N.A.' : (params.data.tenderCig.startsWith('CIGTEMP_' || 'CIGTSTEMP_ ') ? 'N.D.' : params.data.tenderCig);
         return value;
      },
    },
    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 460,
    },
    {
      headerName: 'NR. contratto',
      field: 'contractNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Inizio contratto',
      field: 'date',
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Nome fornitore',
      field: 'supplierName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Importo base €',
      field: 'baseAmount',
      filter: 'agTextColumnFilter',
      //maxWidth: 170,
      valueGetter: (params) => {
        return formatToDecimals(params.data.baseAmount);
      },
      ...columnToRight('fst-italic'),
    },
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      // filter: 'agTextColumnFilter',
      cellStyle: { display: 'flex', justifyContent: 'center' },
      maxWidth: 70,
      minWidth: 70,
      pinned: 'right',
    },
  ];
  const contractStageClColumn = [
    {
      headerName: 'Nr. Contratto',
      field: 'entityNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      //minWidth: 460,
      resizable: true,
    },
    {
      headerName: 'Nr. Risposte',
      field: 'answeredQuestion',
      filter: 'agTextColumnFilter',
      // type: 'rightAligned',
      ...columnCentered('fst-italic'),
      //minWidth: 250,
    },
    {
      headerName: 'Nr. Domande',
      field: 'numberOfQuestions',
      filter: 'agTextColumnFilter',
      // type: 'rightAligned',
      ...columnCentered('fst-italic'),
    },
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      // filter: 'agTextColumnFilter',
      cellStyle: { display: 'flex', justifyContent: 'center' },
      maxWidth: 60,
      minWidth: 60,
      pinned: 'right',
    },
  ];

  const invoicesColumn = [
    {
      headerName: 'Nr. Fattura',
      field: 'number',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'ID Fattura',
      field: 'rpCode',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Data',
      field: 'date',
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      ...columnCentered(),
    },

    {
      headerName: 'Codice Fornitore',
      field: 'supplierCode',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Nome fornitore',
      field: 'supplierName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Totale imponibile €',
      field: 'taxableAmount',
      valueGetter: (params) => {
        return formatToDecimals(params.data.taxableAmount);
      },
      filter: 'agTextColumnFilter',
      type: 'rightAligned',
    },
    {
      headerName: 'F',
      field: 'checkliststatus',
      cellRenderer: ChecklistCellRenderer,
      maxWidth: 70,
      pinned: 'right',
      tooltipComponent: CustomTooltipRenderer,
      tooltipField: 'checkliststatus', //obbligatorio per custom tooltip
      tooltipComponentParams: { type: 'invoices' },
      ...columnCentered('', { textAlign: 'center', alignItems: 'center' }),
    },
    {
      headerName: 'P',
      field: 'invoicePayment',
      cellRenderer: ChecklistCellRenderer,
      cellRendererParams: { type: 'invoicePayment' },
      maxWidth: 70,
      pinned: 'right',
      tooltipComponent: CustomTooltipRenderer,
      tooltipField: 'checkliststatus', //obbligatorio per custom tooltip
      tooltipComponentParams: { type: 'invoicePayment' },
      ...columnCentered('', { textAlign: 'center', alignItems: 'center' }),
    },
    /* {
      //RIATTIVARE QUANDO SARANNO ATTIVE LE QUIETANZE
      headerName: '',
      field: 'invoiceReceipt',
      cellRenderer: ChecklistCellRenderer,
      cellRendererParams: { type: 'invoiceReceipt' },
      maxWidth: 70,
      pinned: 'right',
      tooltipComponent: CustomTooltipRenderer,
      tooltipField: 'checkliststatus', //obbligatorio per custom tooltip
      tooltipComponentParams: { type: 'invoiceReceipt' },
      ...columnCentered('', { textAlign: 'center', alignItems: 'center' }),
    }, */
    {
      headerName: '',
      pinned: 'right',
      minWidth: 70,
      maxWidth: 70,
      cellStyle: { textAlign: 'center', padding: 0 },
      cellRenderer: ProjectInvoiceModal,
      cellRendererParams: {
        setRefresh: setRefresh,
        //contracts: contracts,
      },
    },
  ];
  const invoicesStageColumn = [
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      cellStyle: { display: 'flex', justifyContent: 'center' },
      // filter: 'agTextColumnFilter',
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'CUP',
      field: 'projectCup',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'ID Fattura',
      field: 'rpCode',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Stream',
      field: 'projectStreamName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Sottovoce di spesa',
      field: 'projectExpenseItem',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },

    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 460,
    },
    {
      headerName: 'CIG',
      field: 'tenderCig',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      valueGetter: (params) => {
         const value = params.data.tenderCig.startsWith('AFFIDAMENTO_' || 'SPESEPERSONALE_') ? 'N.A.' : (params.data.tenderCig.startsWith('CIGTEMP_' || 'CIGTSTEMP_ ') ? 'N.D.' : params.data.tenderCig);
         return value;
      },
    },
    {
      headerName: 'Nr. Fattura',
      field: 'number',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Data',
      field: 'date',
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Fattispecie RDI',
      field: 'rdiTypeCode',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Elettronica',
      field: 'electronic',
      cellRenderer: (params) => {
        return params.value ? 'Si' : 'No';
      },
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Nome fornitore',
      field: 'supplierName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: '% IVA',
      field: 'vatPercentage',
      filter: 'agTextColumnFilter',
      //type: 'rightAligned',
      minWidth: 150,
      ...columnToRight('fst-italic'),
    },
    {
      headerName: 'Totale imponibile €',
      field: 'taxableAmount',
      filter: 'agTextColumnFilter',
      //type: 'rightAligned',
      minWidth: 150,
      valueGetter: (params) => {
        return formatToDecimals(params.data.taxableAmount);
      },
      ...columnToRight('fst-italic'),
    },
    {
      headerName: 'Totale fattura €',
      field: 'totalAmount',
      filter: 'agTextColumnFilter',
      //type: 'rightAligned',
      minWidth: 150,
      valueGetter: (params) => {
        return formatToDecimals(params.data.totalAmount);
      },
      ...columnToRight('fst-italic'),
    },
  ];
  const invoicesStageClColumn = [
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      cellStyle: { display: 'flex', justifyContent: 'center' },
      // filter: 'agTextColumnFilter',
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'Nr. Fattura',
      field: 'entityNumber',
      filter: 'agTextColumnFilter',
    },
    //  {
    //    headerName: 'Stato',
    //    field: 'status',
    //    filter: 'agTextColumnFilter',
    //  },

    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      //minWidth: 460,
      resizable: true,
    },
    {
      headerName: 'Nr. Risposte',
      field: 'answeredQuestion',
      filter: 'agTextColumnFilter',
      // type: 'rightAligned',
      ...columnCentered('fst-italic'),
      //minWidth: 250,
    },
    {
      headerName: 'Nr. Domande',
      field: 'numberOfQuestions',
      filter: 'agTextColumnFilter',
      // type: 'rightAligned',
      ...columnCentered('fst-italic'),
    },
  ];

  const timesheetsColumn = [
    {
      headerName: 'Nr. Record',
      field: 'number',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Anno',
      field: 'year',
      valueGetter: (params) => {
        return params?.data?.details?.[0]?.year;
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered(),
    },
    {
      headerName: 'Mese',
      field: 'month',
      valueGetter: (params) => {
        return months()[params?.data?.details?.[0]?.month - 1];
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered(),
    },
    {
      headerName: 'Matricola',
      field: 'serialNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Costo sul Progetto €',
      field: 'projectCost',
      valueGetter: (params) => {
        return formatToDecimals(
          params?.data?.details?.reduce((acc, curr) => {
            return acc + curr.projectCost;
          }, 0)
        );
      },
      type: 'rightAligned',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },

    {
      headerName: '',
      field: 'checkliststatus',
      filter: 'agTextColumnFilter',
      cellRenderer: ChecklistCellRenderer,
      maxWidth: 70,
      cellStyle: { display: 'flex', justifyContent: 'center' },
      pinned: 'right',
    },
    {
      headerName: '',
      pinned: 'right',
      minWidth: 90,
      maxWidth: 90,
      cellStyle: { textAlign: 'center', padding: 0 },
      cellRenderer: ProjectTimesheetModal,
      cellRendererParams: {
        setRefresh: setRefresh,
        //contracts: contracts,
        isProjectSco: project.sco,
      },
    },
  ];
  const timesheetsStageColumn = [
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      // filter: 'agTextColumnFilter',
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'CUP',
      field: 'projectCup',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Stream',
      field: 'projectStreamName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Voce di spesa',
      field: 'projectExpenseItem',
      filter: 'agTextColumnFilter',
      minWidth: 170,
    },

    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 460,
    },
    {
      headerName: 'CIG',
      field: 'tenderCig',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      valueGetter: (params) => {
         const value = params.data.tenderCig.startsWith('AFFIDAMENTO_' || 'SPESEPERSONALE_') ? 'N.A.' : (params.data.tenderCig.startsWith('CIGTEMP_' || 'CIGTSTEMP_ ') ? 'N.D.' : params.data.tenderCig);
         return value;
      },
    },
    {
      headerName: 'Nr. Record',
      field: 'number',
      filter: 'agTextColumnFilter',
      minWidth: 170,
    },
    {
      headerName: 'Anno',
      field: 'year',
      //filter: 'agTextColumnFilter',
      minWidth: 130,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Mese',
      field: 'month',
      cellRenderer: (params) => {
        return months()[params?.value - 1];
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Giorno',
      field: 'day',
      /* cellRenderer: (params) => {
        return months()[params?.value - 1];
      }, */
      //filter: 'agTextColumnFilter',
      minWidth: 130,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Ore lavorate',
      field: 'workHours',
      //filter: 'agTextColumnFilter',
      maxWidth: 160,
      minWidth: 160,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Matricola',
      field: 'serialNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Nominativo',
      field: 'name',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Categoria',
      field: 'category',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Costo orario €',
      field: 'hourlyRate',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return formatToDecimals(params.data.hourlyRate);
      },
      //type: 'rightAligned',
      minWidth: 170,
      ...columnToRight('fst-italic'),
    },
    {
      headerName: 'Ore',
      field: 'projectHours',
      //filter: 'agTextColumnFilter',
      maxWidth: 80,
      minWidth: 80,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Costo sul progetto €',
      field: 'projectCost',
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        return formatToDecimals(params.data.projectCost);
      },
      //type: 'rightAligned',
      minWidth: 220,
      ...columnToRight('fst-italic'),
    },
    {
      headerName: "Lettera d'assegnazione",
      field: 'assignmentLetter',
      filter: 'agTextColumnFilter',
      minWidth: 250,
    },
    {
      headerName: 'Record',
      field: 'timesheetDocument',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Cedolino',
      field: 'payslipDocument',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
  ];
  const timesheetsStageClColumn = [
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      // filter: 'agTextColumnFilter',
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'Nr. Record',
      field: 'entityNumber',
      filter: 'agTextColumnFilter',
    },
    //  {
    //    headerName: 'Stato',
    //    field: 'status',
    //    filter: 'agTextColumnFilter',
    //  },
    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      //minWidth: 460,
      resizable: true,
    },
    {
      headerName: 'Nr. Risposte',
      field: 'answeredQuestion',
      filter: 'agTextColumnFilter',
      ...columnCentered('fst-italic'),
      //minWidth: 250,
    },
    {
      headerName: 'Nr. Domande',
      field: 'numberOfQuestions',
      filter: 'agTextColumnFilter',
      ...columnCentered('fst-italic'),
    },
  ];

  const paymentsColumn = [
    {
      headerName: 'ID Pagamento',
      field: 'paymentNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Data',
      field: 'date',
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered(),
    },
    {
      headerName: 'Codice Fornitore',
      field: 'payeeCode',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Nome Fornitore',
      field: 'payeeName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    // {
    //   headerName: 'Importo €',
    //   field: 'invoiceAmount',
    //   valueGetter: (params) => {
    //     return formatToDecimals(params.data.invoiceAmount);
    //   },
    //   type: 'rightAligned',
    //   //...columnToRight(),
    //   filter: 'agTextColumnFilter',
    //   minWidth: 150,
    // },
    {
      headerName: '',
      pinned: 'right',
      minWidth: 90,
      maxWidth: 90,
      cellStyle: { textAlign: 'center', padding: 0 },
      cellRenderer: ProjectPaymentModal,
      cellRendererParams: {
        setRefresh: setRefresh,
      },
    },
  ];
  const paymentsStageColumn = [
    {
      headerName: '',
      field: 'status',
      cellRenderer: LogCellRenderer,
      // filter: 'agTextColumnFilter',
      maxWidth: 60,
      minWidth: 60,
    },
    {
      headerName: 'ID Pagamento',
      field: 'paymentNumber',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Messaggi',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      minWidth: 460,
    },
    {
      headerName: 'Data',
      field: 'date',
      cellRenderer: (params) => {
        return dateFormatter(params.value);
      },
      //filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnCentered('fst-italic'),
    },
    {
      headerName: 'Codice Fornitore',
      field: 'payeeCode',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Nome Fornitore',
      field: 'payeeName',
      filter: 'agTextColumnFilter',
      minWidth: 150,
    },
    {
      headerName: 'Importo €',
      field: 'invoiceAmount',
      valueGetter: (params) => {
        return formatToDecimals(params.data.invoiceAmount);
      },
      //type: 'rightAligned',
      filter: 'agTextColumnFilter',
      minWidth: 150,
      ...columnToRight('fst-italic'),
    },
  ];
  const paymentsStageClColumn = [
    {
      headerName: 'Nr. Fattura',
      field: 'entityNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Stato',
      field: 'status',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Errori',
      field: 'log',
      cellRenderer: logsCellRenderer,
      filter: 'agTextColumnFilter',
      //minWidth: 460,
      resizable: true,
    },
    {
      headerName: 'Nr. Risposte',
      field: 'answeredQuestion',
      filter: 'agTextColumnFilter',
      ...columnCentered('fst-italic'),
      //minWidth: 250,
    },
    {
      headerName: 'Nr. Domande',
      field: 'numberOfQuestions',
      filter: 'agTextColumnFilter',
      ...columnCentered('fst-italic'),
    },
  ];
  // if (!isOe) return <Unauthorized />;

  return (
    <>
      {/* {sbShow} */}
      <div className='candidacy simple-box scenaritab'>
        <Row>
          <Nav
            variant="pills"
            defaultActiveKey={`${(isOe || userRole === 5) ? activeKey : 'industrialaccounts'}`}
            onSelect={(k) => {
              setActiveKey(k);
              setSbShow(<></>);
            }}>
            {(isOe || userRole === 5) && (
              <>
               {/* se progetto di e-distribuzione */}
               {project.companyId === 2 ? 
                <Nav.Item>
                  <Nav.Link
                    eventKey='configuration-template'
                    as={NavLink}
                    to={`/project/${projectId}/${tab}/${submenu}/configuration-template`}>
                    Configurazione File Template
                  </Nav.Link>
                </Nav.Item> : null}
                <Nav.Item>
                  <Nav.Link
                    eventKey='contracts'
                    as={NavLink}
                    to={`/project/${projectId}/${tab}/${submenu}/contracts`}>
                    Incarichi / attingimenti
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='invoices'
                    as={NavLink}
                    to={`/project/${projectId}/${tab}/${submenu}/invoices`}>
                    Fatture
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='payments'
                    as={NavLink}
                    to={`/project/${projectId}/${tab}/${submenu}/payments`}>
                    Pagamenti
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey='timesheets'
                    as={NavLink}
                    to={`/project/${projectId}/${tab}/${submenu}/timesheets`}>
                    Personale
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            <Nav.Item>
              <Nav.Link
                eventKey='industrialaccounts'
                as={NavLink}
                to={`/project/${projectId}/${tab}/${submenu}/industrialaccounts`}>
                Contabilità Industriale
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>

        <Switch>
          {project.companyId === 2 ? 
           <Route path={`/project/:projectId/:tab/:submenu/configuration-template`}>
            <GenerateTemplatesSection 
               projectCompanyId={project.companyId}
               projectId={projectId}
               sbShow={sbShow}
               setSbShow={setSbShow}
            />
           </Route> : null 
          }
          <Route path={`/project/:projectId/:tab/:submenu/contracts`}>
            <MassiveUploadProject
              column={contractColumn}
              columnStage={contractStageColumn}
              columnStageCl={contractStageClColumn}
              entityType='contracts'
              projectId={project.id}
              sbShow={sbShow}
              setSbShow={setSbShow}
              errors={errorsContracts}
              setErrors={setErrorsContracts}
              projectName={project.name}
              projectCup={project.cup}
              key='contracts-key'></MassiveUploadProject>
          </Route>
          <Route path={`/project/:projectId/:tab/:submenu/invoices`}>
            <MassiveUploadProject
              column={invoicesColumn}
              columnStage={invoicesStageColumn}
              columnStageCl={invoicesStageClColumn}
              entityType='invoices'
              projectId={project.id}
              sbShow={sbShow}
              setSbShow={setSbShow}
              errors={errorsInvoices}
              setErrors={setErrorsInvoices}
              projectName={project.name}
              projectCup={project.cup}
              key='invoices-key'></MassiveUploadProject>
          </Route>
          <Route path={`/project/:projectId/:tab/:submenu/payments`}>
            <MassiveUploadProject
              column={paymentsColumn}
              columnStage={paymentsStageColumn}
              columnStageCl={paymentsStageClColumn}
              entityType='payments'
              projectId={project.id}
              sbShow={sbShow}
              setSbShow={setSbShow}
              errors={errorsPayments}
              setErrors={setErrorsPayments}
              projectName={project.name}
              projectCup={project.cup}
              key='payments-key'></MassiveUploadProject>
          </Route>
          <Route path={`/project/:projectId/:tab/:submenu/timesheets`}>
            <MassiveUploadProject
              column={timesheetsColumn}
              columnStage={timesheetsStageColumn}
              columnStageCl={timesheetsStageClColumn}
              entityType='timesheets'
              projectId={project.id}
              sbShow={sbShow}
              setSbShow={setSbShow}
              errors={errorsTimesheets}
              setErrors={setErrorsTimesheets}
              projectName={project.name}
              projectCup={project.cup}
              key='timesheets-key'></MassiveUploadProject>
          </Route>
          <Route path={`/project/:projectId/:tab/:submenu/industrialaccounts`}>
            <Row>
              {/*<IndustrialAccounts projectId={project.id} />*/}
              <IndustrialAccounts projectId={project.id} />
            </Row>
          </Route>
        </Switch>
      </div>
    </>
  );
};
export default Execution;
