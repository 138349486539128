import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Row,
  Col,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import {
  getStatementInvoicesOrTimesheets,
  pickUnpickProjectStatement,
  pickUnpickAllProjectStatement,
  getProjectStatementAmounts,
  getProjectStatementGeneralExpenses,
  editProjectStatement,
  getProjectStatement,
  deleteStatement,
} from "@lib/api";
import moment from "moment";
import { IcoEdit, IcoCanc, IcoRefresh } from "@icons";
import StatusBar from "@components/StatusBar";
import { InputNumber } from "primereact";
import { customCellStyle } from "@lib/helpers/agGridHelpers";
import { formatToDecimals, dateFormatterInput } from "@lib/helpers/formatting";
import OETableAgGrid from "components/oetable/OETableAgGrid";
import { months } from "@lib/helpers/calendar";
import CheckDocTab from "@components/projectStatements/CheckDocTab";
import DataHeader from "components/DataHeader";
import UploadDocument from "components/form/upload/uploadDocuments";
import { translate } from "components/translation/translation";
import { OETrans } from "components/translation/OETrans";

const MakeReportingModal = ({
  data,
  isOe,
  projectId,
  newStatement,
  setRefresh,
  isShowNew,
  setIsShowNew,
  localLang,
  optionsStatementTypes,
  refreshTotals,
}) => {
  const [detail, setDetail] = useState({});
  const [amounts, setAmounts] = useState({ total: 0, requestedAmount: 0 });
  const [timesheets, setTimesheets] = useState(null);
  const [timesheetsMeta, setTimesheetsMeta] = useState({});
  const [invoices, setInvoices] = useState(null);
  const [invoicesMeta, setInvoicesMeta] = useState({});
  const [generalExpenses, setGeneralExpenses] = useState([]);
  const [originCheckedInvoices, setOriginCheckedInvoices] = useState([]);
  const [originCheckedTimesheets, setOriginCheckedTimesheets] = useState([]);
  //const [originCheckedInvoicesMeta, setOriginCheckedInvoicesMeta] = useState({});
  //const [originCheckedTimesheetsMeta, setOriginCheckedTimesheetsMeta] = useState({});
  const [showModal, setShowModal] = useState(
    typeof isShowNew === "boolean" ? isShowNew : false
  );
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalValidate, setShowModalValidate] = useState(false);
  const [activeKey, setActiveKey] = useState(
    data && Object.values(data).length > 0 && data.state === "VALIDATED"
      ? "inputs"
      : data?.statementTypeCode === "ADV" ||
        newStatement?.statementTypeCode === "ADV"
      ? "documents"
      : "invoices"
  );
  const [sbShow, setSbShow] = useState(<></>);
  const [sbShowValidate, setSbShowValidate] = useState(<></>);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoadingAmount, setIsLoadingAmount] = useState(false);
  const [indireactCosts, setIndireactCosts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  //console.log('amounts', amounts);
  //console.log('detail', detail);

  useEffect(() => {
    if (data || newStatement) {
      const tempDetail = data ?? newStatement;
      setDetail(tempDetail);
      setAmounts({
        total: tempDetail.amount,
        requestedAmount: tempDetail.requestedAmount,
      });
    }
    setIndireactCosts(data?.indirectCost ?? newStatement?.indirectCost ?? 0);
  }, [data, newStatement]);

  useEffect(() => {
    if (detail.state) {
      setIsValidated(detail.state === "VALIDATED" ? true : false);
    }
  }, [detail.state]);

  useEffect(() => {
    if (detail.statementTypeCode === "ADV") return;
    if (projectId && detail.id) {
      setIsLoading(true);
      getProjectStatement(projectId, detail.id).then((retrievedElement) => {
        if (retrievedElement.success) {
          setIndireactCosts(retrievedElement.data.indirectCost);
        }
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originCheckedTimesheets]);

  useEffect(() => {
    if (showModal && projectId && detail.id) {
      handleRenderData();
      setSbShow(<></>);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, detail.id, showModal]);

  const handleRenderData = async () => {
    if (detail.statementTypeCode === "ADV") return;
    getStatementInvoicesOrTimesheets(
      projectId,
      detail.id,
      "invoices",
      "invoicesStatements"
    ).then((retrievedElements) => {
      if (retrievedElements.success) {
        setInvoices(retrievedElements.data);
        const { data, success, ...restResp } = retrievedElements;
        setInvoicesMeta(restResp);

        setOriginCheckedInvoices(
          retrievedElements.data.filter((el) => el.checked)
        ); //TOGLIERE FILTRO
        //setOriginCheckedInvoicesMeta(restResp);
      }
    });

    getStatementInvoicesOrTimesheets(
      projectId,
      detail.id,
      "timesheets",
      "timesheetsStatements"
    ).then((retrievedElements) => {
      if (retrievedElements.success) {
        setTimesheets(retrievedElements.data);
        const { data, success, ...restResp } = retrievedElements;
        setTimesheetsMeta(restResp);

        setOriginCheckedTimesheets(
          retrievedElements.data.filter((el) => el.checked)
        ); //TOGLIERE FILTRO
        //setOriginCheckedTimesheetsMeta(restResp);
      }
    });

    getProjectStatementGeneralExpenses(projectId, detail.id).then(
      (retrievedGeneralExpenses) => {
        if (retrievedGeneralExpenses.success) {
          setGeneralExpenses(retrievedGeneralExpenses.data.statementGe);
        }
      }
    );

    handleRefreshDataAmount();
  };

  const handleRefreshDataAmount = async () => {
    if (detail.statementTypeCode === "ADV") return;

    getProjectStatementAmounts(projectId, detail.id).then(
      (retrievedElement) => {
        if (retrievedElement.success) {
          //console.log('retrievedElement', retrievedElement);

          setAmounts(retrievedElement.data);

          setIsLoadingAmount(false);
        }
      }
    );
  };

  const handleShowModal = (setShowModal) => setShowModal(true);

  const handleCloseModal = (setShowModal) => {
    setShowModal(false);
    setActiveKey("invoices");
    setSbShow(<></>);
    setRefresh(true);
    setIsShowNew(false);
    if (refreshTotals) refreshTotals();
  };

  const handleStatusBar = (status, message, timeout, setSb = setSbShow) => {
    setSb(<StatusBar status={status} message={message} timeout={timeout} />);
  };

  /* {
      id: 211,  XXXXXX
      amount: 53976,  XXXXXX
      number: "1/70",  XXXXXX
      name: "Acquisto macchinari",  XXXXXX
      position: 1,  XXXXXX
      checked: 0,  XXXXXX
      dueDate: null,  XXXXXX
      quotaNumber: 1,
      reportableAmount: null,  XXXXXX
      invoiceId: 155,
      rpCode: "5105442166",
      supplierName: "MECA TECNOLOGIE ELETTROMECCANICHE S",  XXXXXX
      supplierCode: "0010000380",
      generalExpenses: 1
  }, */

  const invoicesReportColumn = [
    {
      headerName: "",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      field: "id",
      hide: isValidated ? true : false,
      maxWidth: 50,
      resizable: false,
      checkboxSelection: isValidated ? false : true,
    },
    {
      headerName: "Nr. Fattura",
      field: "number",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "ID Fattura",
      field: "rpCode",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Fornitore",
      field: "supplierName",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Voce di spesa",
      field: "name",
      filter: "agTextColumnFilter",
      //minWidth: 100,
    },
    /* {
         headerName: 'Scadenza',
         field: 'dueDate',
         //filter: 'agDateColumnFilter',
         maxWidth: 150,
         valueGetter: (params) => {
            return params.data.dueDate ? dateFormatter(params.data.dueDate) : '';
         },
         ...columnCentered(),
      }, */
    {
      headerName: "Importo rendicontabile €",
      field: "amount",
      filter: "agTextColumnFilter",
      type: "rightAligned",
      resizable: true,
      valueGetter: (params) => {
        return params.data.amount ? formatToDecimals(params.data.amount) : 0;
      },
    },
  ];

  const timesheetsReportColumn = [
    {
      headerName: "",
      field: "id",
      headerCheckboxSelection: true,
      hide: isValidated ? true : false,
      maxWidth: 50,
      resizable: false,
      checkboxSelection: isValidated ? false : true,
    },
    {
      headerName: "Matricola",
      field: "serialNumber",
      filter: "agTextColumnFilter",
      minWidth: 150,
      /* valueGetter: (params) => {
            return params.data.timesheetId
            + '-' + params.data.serialNumber;
         }, */
    },
    {
      headerName: "Categoria",
      field: "category",
      filter: "agTextColumnFilter",
      cellStyle: {
        ...customCellStyle,
      },
      cellRendererParams: {
        queryParam: "category",
      },
    },
    {
      headerName: "Record",
      //field: 'timesheetId',
      field: "number",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Mese",
      field: "month",
      headerClass: "text-center",
      //filter: 'agTextColumnFilter',
      //   ...columnCentered(),
      cellStyle: { ...customCellStyle, justifyContent: "center" },
      valueGetter: (params) => {
        return months()[params.data.month - 1];
      },
      cellRendererParams: {
        queryParam: "month",
      },
    },
    {
      headerName: "Anno",
      field: "year",
      headerClass: "text-center",
      cellRendererParams: {
        queryParam: "year",
      },
      cellStyle: { ...customCellStyle, justifyContent: "center" },
    },
    {
      headerName: "Costo totale €",
      field: "totalCost",
      filter: "agTextColumnFilter",
      type: "rightAligned",
      cellStyle: {
        ...customCellStyle,
        justifyContent: "right",
      },
      valueGetter: (params) => {
        return params.data.totalCost
          ? formatToDecimals(params.data.totalCost)
          : 0;
      },
    },
  ];

  const handleChanges = (e, fieldName) => {
    let tempDetail = { ...detail };
    let value;

    switch (fieldName) {
      case "date":
      case "cashedInDate":
        value = e.target.value;

        tempDetail[fieldName] = value;
        break;
      case "checkEnvironmentalTagging":
      case "checkDigitalTagging":
      case "checkGenderEquality":
      case "checkDnsh":
      case "cashedIn":
        value = e.target.checked;

        tempDetail[fieldName] = value;

        if (fieldName === "cashedIn" && e.target.checked === false) {
          tempDetail.cashedInDate = null;
        }
        break;
      case "approvedAmount":
        value = Number(e.value);

        tempDetail[fieldName] = value;
        break;
      default:
        break;
    }
    //console.log('tempDetail', tempDetail);
    setDetail(tempDetail);
    handleEdit(tempDetail);
  };

  const handleEdit = async (tempDetail) => {
    setSbShow(<></>);
    setSbShowValidate(<></>);

    const { createdAt, createdBy, updatedAt, updatedBy, ...restDetail } =
      tempDetail;

    if (tempDetail.date && data.date === tempDetail.date) {
      delete restDetail.date;
    }

    let result = await editProjectStatement(projectId, restDetail);
    //console.log('result', result);

    if (result.success) {
      //handleStatusBar('success', translate('common.message.edited', 'Elemento modificato con successo'), true); //ELIMINARE

      setTimeout(() => {
        handleRenderData();
        setDetail(result.data);
      }, 500);
    } else {
      handleStatusBar(
        "error",
        result.message ??
          translate(
            "common.error.save",
            "Si è verificato un errore durante il salvataggio delle informazioni"
          ),
        false
      );
    }
  };

  const handleItemList = async (
    [entityType, originItemList, setOriginItemList],
    itemList
  ) => {
    let action = undefined;

    if (itemList.length === 0) {
      action = "unpickall";
    } else if (
      itemList.length ===
      (entityType === "invoices" ? invoices.length : timesheets.length)
    ) {
      action = "pickall";
    }

    if (action) {
      const result = await pickUnpickAllProjectStatement(
        projectId,
        detail.id,
        entityType,
        action
      );

      if (result.success) {
        setOriginItemList(itemList);
        handleRefreshDataAmount();
      } else {
        handleStatusBar(
          "error",
          result.message ??
            translate(
              "common.error.save",
              "Si è verificato un errore durante il salvataggio delle informazioni"
            ),
          false
        );
      }
      return;
    }

    let resultOfActionsTemp = [];
    let messageResult = [];

    const oldItemsDeleted = originItemList.filter((object1) => {
      return !itemList.some((object2) => {
        return object1.timesheetId
          ? Number(object1.timesheetId) === Number(object2.timesheetId)
          : Number(object1.id) === Number(object2.id);
      });
    });
    //console.log('oldItemsDeleted', oldItemsDeleted);

    if (oldItemsDeleted.length > 0) {
      await Promise.all(
        oldItemsDeleted.map(async (item) => {
          const deleteResult = await pickUnpickProjectStatement(
            projectId,
            detail.id,
            entityType,
            "unpick",
            {
              [entityType === "invoices" ? "quotaId" : "timesheetId"]:
                item.id || item.timesheetId,
            }
          );
          //console.log('deleteResult', deleteResult);

          if (deleteResult.success === false) messageResult.push(deleteResult);
          resultOfActionsTemp.push(deleteResult.success);
        })
      );
    }

    let newItems = itemList.filter((object1) => {
      return !originItemList.some((object2) => {
        return object1.timesheetId
          ? Number(object1.timesheetId) === Number(object2.timesheetId)
          : Number(object1.id) === Number(object2.id);
      });
    });
    //console.log('newItems', newItems);

    if (newItems.length > 0) {
      await Promise.all(
        newItems.map(async (newItem) => {
          const result = await pickUnpickProjectStatement(
            projectId,
            detail.id,
            entityType,
            "pick",
            {
              [entityType === "invoices" ? "quotaId" : "timesheetId"]:
                newItem.id || newItem.timesheetId,
            }
          );
          //console.log('result:', result);

          if (result.success === false) messageResult.push(result);
          resultOfActionsTemp.push(result.success);
        })
      );
    }

    //console.log('resultOfActionsTemp', resultOfActionsTemp);
    //console.log('messageResult', messageResult);

    if (
      resultOfActionsTemp.length === 0 ||
      (resultOfActionsTemp.length > 0 &&
        resultOfActionsTemp.every((el) => el === true))
    ) {
      setOriginItemList(itemList);
      handleRefreshDataAmount();

      //handleStatusBar('success', 'Elemento selezionato con successo', true); //ELIMINARE
    } else {
      handleStatusBar(
        "error",
        messageResult.length > 0
          ? Object.values(messageResult[0].data).join()
          : translate(
              "common.error.save",
              "Si è verificato un errore durante il salvataggio delle informazioni"
            ),
        false
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSbShow(<></>);
    setSbShowValidate(<></>);

    //const { createdAt, createdBy, updatedAt, updatedBy, ...restDetail } = detail;
    //restDetail.state = 'VALIDATED';

    let result = await editProjectStatement(projectId, {
      id: detail.id,
      state: "VALIDATED",
    });
    //console.log('result', result);

    if (result.success) {
      handleStatusBar(
        "success",
        "Elemento validato con successo",
        true,
        setSbShowValidate
      );

      setTimeout(() => {
        handleCloseModal(setShowModal);
      }, 500);
    } else {
      handleStatusBar(
        "error",
        result.message ??
          translate(
            "common.error.save",
            "Si è verificato un errore durante il salvataggio delle informazioni"
          ),
        false,
        setSbShowValidate
      );
    }
  };

  const handleDelete = async () => {
    const deleteResult = await deleteStatement(projectId, detail.id);

    if (deleteResult.success) {
      handleStatusBar(
        "success",
        translate("common.message.deleted", "Elemento eliminato correttamente"),
        true
      );

      setTimeout(() => {
        setShowModalDelete(false);
        setRefresh(true);
      }, 500);
    } else {
      handleStatusBar(
        "error",
        deleteResult.message ??
          translate(
            "common.error.delete",
            "Si è verificato un errore durante l’eliminazione delle informazioni"
          ),
        false
      );
    }
  };

  const editTooltip = (props) => (
    <Tooltip {...props}>
      <OETrans code="common.tooltip.edit" fallback={"Modifica"} />
    </Tooltip>
  );
  const deleteTooltip = (props) => (
    <Tooltip {...props}>
      <OETrans code="common.tooltip.delete" fallback={"Elimina"} />
    </Tooltip>
  );

  const buttonRefresh = () => {
    return (
      <>
        {!isValidated && (
          <Button
            onClick={() => {
              setIsLoadingAmount(true);
              handleRefreshDataAmount();
            }}
            className="m-0"
            style={{ padding: "0 0.2rem", width: "27px" }}
          >
            {isLoadingAmount ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <IcoRefresh width="25px" height="25px" />
            )}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      {isOe ? (
        <>
          <OverlayTrigger placement="top" overlay={editTooltip}>
            <Button
              variant="link"
              className={isValidated ? "" : "me-3"}
              onClick={() => handleShowModal(setShowModal)}
            >
              <IcoEdit className="second-color ico-small edit" />
            </Button>
          </OverlayTrigger>
          {!isValidated && (
            <OverlayTrigger placement="top" overlay={deleteTooltip}>
              <Button
                variant="link"
                className="me-0"
                onClick={() => handleShowModal(setShowModalDelete)}
              >
                <IcoCanc className="second-color ico-small cancel" />
              </Button>
            </OverlayTrigger>
          )}
        </>
      ) : null}

      {showModal && Object.values(detail).length > 0 && (
        <Modal
          fullscreen={true}
          className="modal-with-nav"
          show={showModal}
          onHide={() => handleCloseModal(setShowModal)}
          centered
        >
          <Modal.Header
            style={{ maxHeight: "7rem", paddingLeft: "2.5rem" }}
            closeButton
          >
            <Modal.Title className="col-auto pt-0 px-2">
              <DataHeader
                customStyle={{
                  margin: 0,
                  paddingTop: "5px",
                  paddingBottom: 0,
                  marginLeft: isValidated ? "5px" : "-35px",
                }}
                data={[
                  {
                    name: "ID",
                    value:
                      detail.name.trim() ||
                      optionsStatementTypes
                        .filter(
                          (optType) =>
                            optType.value === detail.statementTypeCode
                        )
                        .map((type) => type.label)
                        .pop(),
                  },
                  {
                    name: "Importo",
                    //value: (Object.keys(amounts).length > 0 ? formatToDecimals(amounts.total) : 0) + " €",
                    value:
                      (detail.state === "DRAFT" &&
                      Object.keys(amounts).length > 0
                        ? formatToDecimals(amounts.total)
                        : formatToDecimals(detail.amount)) + " €",
                    icon: buttonRefresh(),
                  },
                  {
                    name: (
                      <span className="text-nowrap">Contributo richiesto</span>
                    ),
                    //value: (Object.keys(amounts).length > 0 ? formatToDecimals(amounts.requestedAmount) : 0) + ' €',
                    value:
                      (detail.state === "DRAFT" &&
                      Object.keys(amounts).length > 0
                        ? formatToDecimals(amounts.requestedAmount)
                        : formatToDecimals(detail.requestedAmount)) + " €",
                  },
                ]}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              className="mb-4"
              activeKey={activeKey}
              onSelect={(k) => {
                setActiveKey(k);
                handleRenderData();
                setSbShow(<></>);
              }}
              fill={detail.statementTypeCode !== "ADV" ? true : false}
              variant="pills"
            >
              {isValidated && (
                <Tab eventKey="inputs" title="Approvazione">
                  <Form>
                    <Row style={{ marginBottom: "40px" }}>
                      <Col className="col">
                        <Form.Group>
                          <Form.Label>Sottomissione al Ministero</Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={
                              detail.date ? dateFormatterInput(detail.date) : ""
                            }
                            onChange={(e) => handleChanges(e, "date")}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="col">
                        <Form.Group>
                          <Form.Label>Contributo approvato €</Form.Label>
                          <InputNumber
                            name="approvedAmount"
                            min={0}
                            step={1}
                            minFractionDigits={0}
                            maxFractionDigits={2}
                            //onChange={(e) => onChange(e, 'approvedAmount')}
                            onValueChange={(e) =>
                              handleChanges(e, "approvedAmount")
                            }
                            mode="decimal"
                            locale={localLang}
                            className="md d-block"
                            //suffix={' €'}
                            value={detail.approvedAmount ?? ""}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="col">
                        <Form.Group className="col-auto">
                          <Form.Label>Incassato</Form.Label>
                          <Form.Check
                            type="checkbox"
                            name="cashedIn"
                            defaultChecked={detail.cashedIn}
                            onClick={(e) => handleChanges(e, "cashedIn")}
                          />
                        </Form.Group>
                      </Col>

                      <Col className="col">
                        <Form.Group as={Col}>
                          <Form.Label>In data</Form.Label>
                          <Form.Control
                            type="date"
                            name="cashedInDate"
                            value={
                              detail.cashedInDate
                                ? moment(detail.cashedInDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""
                            }
                            onChange={(e) => handleChanges(e, "cashedInDate")}
                            disabled={detail.cashedIn === false}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Tab>
              )}

              {isValidated && detail.statementTypeCode !== "ADV" && (
                <Tab eventKey="integration" title="Integrazione">
                  <Row style={{ marginBottom: "40px" }}>
                    <Col>
                      {activeKey === "integration" && (
                        <CheckDocTab
                          tab="integration"
                          //conditionalitiesFilterCodes={docCodeIntegration}
                          conditionalitiesFilterCodes={["Integrazione"]}
                          detail={detail}
                          isValidated={isValidated}
                          setDetail={setDetail}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab>
              )}

              {detail.statementTypeCode !== "ADV" && (
                <Tab eventKey="invoices" title="Forniture esterne">
                  {activeKey === "invoices" && (
                    <OETableAgGrid
                      column={invoicesReportColumn}
                      {...(isValidated
                        ? { data: invoices, dataMeta: invoicesMeta }
                        : {
                            data: invoices,
                            dataMeta: invoicesMeta,
                            conditionForCheck: "node.data.checked === 1",
                            handleItemListSelection: handleItemList,
                            handleItemListSelectionProps: [
                              "invoices",
                              originCheckedInvoices,
                              setOriginCheckedInvoices,
                            ],
                          })}
                      refTable="invoicesStatements"
                    />
                  )}
                </Tab>
              )}
              {detail.statementTypeCode !== "ADV" && (
                <Tab eventKey="timesheets" title="Personale interno">
                  {activeKey === "timesheets" && (
                    <OETableAgGrid
                      children={
                        <Row className="justify-content-start align-items-center">
                          <Col>
                            <Row>
                              {isLoading ? (
                                <Spinner animation="border" role="status" />
                              ) : (
                                indireactCosts > 0 && (
                                  <>
                                    <h5 className="m-0">
                                      {formatToDecimals(indireactCosts) + " €"}
                                    </h5>
                                    <p className="m-0 dataHeaderName">
                                      Costi indiretti
                                    </p>
                                  </>
                                )
                              )}
                            </Row>
                          </Col>
                        </Row>
                      }
                      column={timesheetsReportColumn}
                      {...(isValidated
                        ? { data: timesheets, dataMeta: timesheetsMeta }
                        : {
                            data: timesheets,
                            dataMeta: timesheetsMeta,
                            conditionForCheck: "node.data.checked === 1",
                            handleItemListSelection: handleItemList,
                            handleItemListSelectionProps: [
                              "timesheets",
                              originCheckedTimesheets,
                              setOriginCheckedTimesheets,
                            ],
                          })}
                      refTable="timesheetsStatements"
                    />
                  )}
                </Tab>
              )}

              {detail.statementTypeCode !== "ADV" && (
                <Tab eventKey="general-expenses" title="Spese generali">
                  {generalExpenses.length > 0 ? (
                    <>
                      <Row className="mx-5 mt-5 justify-content-between">
                        <Col className="col-3">
                          <Form.Label className="mb-3">Descrizione</Form.Label>
                        </Col>

                        <Col className="col-3 text-end">
                          <Form.Label className="mb-3">Importo €</Form.Label>
                        </Col>

                        <Col className="col-3 text-end">
                          <Form.Label className="mb-3">
                            Percentuale forfettaria %
                          </Form.Label>
                        </Col>

                        <Col className="col-3 text-end">
                          <Form.Label className="mb-3">
                            Importo spese generali €
                          </Form.Label>
                        </Col>
                      </Row>

                      {generalExpenses.map((generalExpense) => {
                        return (
                          <Row
                            key={generalExpense.id}
                            className="mx-5 mt-4 justify-content-between"
                          >
                            <Col className="col-3">
                              <div>{generalExpense.expenseItem.name}</div>
                            </Col>

                            <Col className="col-3">
                              <div className="text-end">
                                {generalExpense.amount
                                  ? formatToDecimals(generalExpense.amount)
                                  : 0}
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="text-end">
                                {generalExpense.geShare
                                  ? formatToDecimals(
                                      generalExpense.geShare,
                                      0,
                                      2
                                    )
                                  : 0}
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="text-end">
                                {generalExpense.geAmount
                                  ? formatToDecimals(generalExpense.geAmount)
                                  : 0}
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  ) : (
                    <Row className="text-center my-5">
                      <span>
                        Non sono presenti spese generali per questo rendiconto
                      </span>
                    </Row>
                  )}
                </Tab>
              )}

              {detail.statementTypeCode !== "ADV" && (
                <Tab
                  eventKey="administrative-accounting"
                  title="Altra documentazione"
                >
                  <Row className="mb-2rem mt-5">
                    <Col>
                      {activeKey === "administrative-accounting" && (
                        <CheckDocTab
                          tab="administrative-accounting"
                          //conditionalitiesFilterCodes={docCodeAdministrativeAccounting}
                          conditionalitiesFilterCodes={[
                            "Personale",
                            "Forniture",
                          ]}
                          detail={detail}
                          isValidated={isValidated}
                          setDetail={setDetail}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab>
              )}

              <Tab
                eventKey="documents"
                title={
                  detail.statementTypeCode === "ADV"
                    ? "Richiesta"
                    : "Domanda di Rimborso"
                }
              >
                <Row className="mb-2rem mt-5">
                  <Col>
                    {activeKey === "documents" && (
                      <UploadDocument
                        isValidated={isValidated}
                        disableDelete={true}
                        displayName={true}
                        filterBySection={
                          detail?.statementTypeCode === "ADV"
                            ? ["Anticipo"]
                            : ["Bando"]
                        }
                        entity="statements"
                        entityId={detail.id}
                        enableUpload={true}
                      />
                    )}
                  </Col>
                </Row>
              </Tab>
            </Tabs>

            {sbShow}
          </Modal.Body>

          <Modal.Footer>
            <Col className="col">&zwnj;</Col>

            <Col className="col text-center">
              {!isValidated && (
                <Button
                  style={{ maxWidth: "fit-content" }}
                  type="submit"
                  onClick={() => handleShowModal(setShowModalValidate)}
                  form="valid-report"
                  variant="primary"
                >
                  VALIDA RENDICONTO
                </Button>
              )}
            </Col>

            <Col>
              <Row className="justify-content-end">
                {/* <Col className="col-auto">
                           <Button as={Col} variant='secondary' onClick={() => handleCloseModal(setShowModal)}>
                              CHIUDI
                           </Button>
                        </Col> */}
                {/* {activeKey !== 'documents' ? <Col className="col-auto">
                           <Button
                              as={Col}
                              type='submit'
                              onClick={(e) => handleEdit(e)}
                              form='edit-statement'
                              variant='primary'
                              disabled={isValidated || activeKey !== 'conditionality'}
                           >
                              <OETrans code='common.edit' fallback={'Modifica'}/>
                           </Button>
                        </Col> :
                           null
                        } */}
              </Row>
            </Col>
          </Modal.Footer>
        </Modal>
      )}

      <Modal
        show={showModalDelete}
        onHide={() => handleCloseModal(setShowModalDelete)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <OETrans code="common.warning" fallback={"Attenzione"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span>
              <OETrans
                code="common.warning.delete"
                fallback={"Sei sicuro di voler eliminare questo elemento?"}
              />
            </span>
          </div>
          {sbShow}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModal(setShowModalDelete)}
          >
            <OETrans code="common.cancel" fallback={"Annulla"} />
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            <OETrans code="common.delete" fallback={"Elimina"} />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalValidate}
        onHide={() => setShowModalValidate(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <OETrans code="common.warning" fallback={"Attenzione"} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-3">
            <span>Sei sicuro di voler validare il rendiconto?</span>
          </div>
          {sbShowValidate}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalValidate(false)}
          >
            <OETrans code="common.cancel" fallback={"Annulla"} />
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Valida
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MakeReportingModal;
