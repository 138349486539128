import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Form, Row, Col, Tab, Tabs, Spinner } from 'react-bootstrap';
import { getEntity } from '@lib/api';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { formatToDecimals, dateFormatter } from '@lib/helpers/formatting';
import { columnCentered } from '@lib/helpers/agGridHelpers';
import { LevelContext } from 'components/contexts/LevelContext';
import OESimpleTable from 'components/oetable/OESimpleTable';
import { OETrans } from 'components/translation/OETrans';

const InvoiceDetailSummaryModal = ({
   data,
   setRefresh,
   modalIsVisible,
   setModalIsVisible,
   setModalData,
   spinnerVisible,
   isSummary,
}) => {
   const { userRole } = useContext(LevelContext);
   const { projectId } = useParams();
   const [detail, setDetail] = useState({});
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isEdited, setIsEdited] = useState(false);
   const [rowData, setRowData] = useState([]);
   const [columnDef, setColumnDef] = useState([]);
   //console.log('detail', detail);

   function transformData(positions) {
      const transformedData = [];

      if (positions && Array.isArray(positions)) {
         positions.forEach((position) => {
            if (position && position.quotas && Array.isArray(position.quotas)) {
               position.quotas.forEach((quota) => {
                  const newObject = { ...position };
                  delete newObject.quotas;

                  if (quota) {
                     Object.keys(quota).forEach((key) => {
                        newObject[key + 'Q'] = quota[key];
                     });

                     transformedData.push(newObject);
                  }
               });
            }
         });
      }

      setRowData(transformedData);
   }

   useEffect(() => {
      if (modalIsVisible && projectId && data.id) {
         getEntity('invoices', projectId, data.id).then((retrievedInvoice) => {
            if (retrievedInvoice.success) {
               setDetail(retrievedInvoice.data);
               transformData(retrievedInvoice.data.positions);
            }
         });
      }
   }, [data, modalIsVisible, projectId]);

   const handleCloseModal = (setShowModal) => {
      if (isEdited) {
         setRefresh(true);
         setIsEdited(false);
      }
      setSbShow(<></>);
      setModalData([]);
      setShowModal(false);
   };

   useEffect(() => {
      setColumnDef([
         {
            headerName: 'Posizione',
            field: 'position',
            filter: 'agTextColumnFilter',
            minWidth: 150,
         },
         {
            headerName: 'Quota',
            field: 'quotaNumberQ',
            // field: 'quotaID',
            filter: 'agTextColumnFilter',
            minWidth: 120,
         },
         {
            headerName: 'Descrizione Posizione',
            field: 'positionDescription',
            tooltipField: 'positionDescription',
            filter: 'agTextColumnFilter',
            minWidth: 380,
         },
         {
            headerName: 'Classificazione interna',
            field: 'internalTaxonomy',
            tooltipField: 'internalTaxonomy',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return params.data.internalTaxonomy ?? 'N.D.';
            },
            minWidth: 300,
         },
         /* {
            headerName: 'Matricola Bene ENEL',
            field: 'serialNumberEnel',
            filter: 'agTextColumnFilter',
            minWidth: 220,
         },
         {
            headerName: 'Descrizione Bene ENEL',
            field: 'descriptionEnel',
            filter: 'agTextColumnFilter',
            minWidth: 380,
         }, */
         {
            headerName: 'OT',
            field: 'otQ',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.otQ ?? 'N.D.';
            },
         },
         {
            headerName: 'Matricola Materiale',
            field: 'code',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.material?.code ?? 'N.D.';
            },
         },
         {
            headerName: 'Codice Impianto',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.plant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 220,
            valueGetter: (params) => {
               return params.data.plant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.plant?.plantName ?? 'N.D.';
            },
         },
         {
            headerName: 'Codice Impianto Primario',
            field: 'plantCode',
            filter: 'agTextColumnFilter',
            minWidth: 250,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantCode ?? 'N.D.';
            },
         },
         {
            headerName: 'Tipo Impianto Primario',
            field: 'plantType',
            filter: 'agTextColumnFilter',
            minWidth: 240,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantType ?? 'N.D.';
            },
         },
         {
            headerName: 'Denominazione Impianto Primario',
            field: 'plantName',
            filter: 'agTextColumnFilter',
            minWidth: 320,
            valueGetter: (params) => {
               return params.data.parentPlant?.plantName ?? 'N.D.';
            },
         },
         {
            headerName: 'Etichettabile',
            field: 'labelable',
            filter: 'agTextColumnFilter',
            minWidth: 170,
         },
         {
            headerName: 'Tracciabile',
            field: 'trackable',
            filter: 'agTextColumnFilter',
            minWidth: 150,
         },
         {
            headerName: 'QTA. POS. Acquistata',
            field: 'quantity',
            filter: 'agTextColumnFilter',
            type: 'rightAligned',
            minWidth: 220,
         },
         {
            headerName: 'QTA. POS. Consuntivata',
            field: 'finalQuantity',
            filter: 'agTextColumnFilter',
            type: 'rightAligned',
            minWidth: 250,
         },
         {
            headerName: 'Importo POS. Consuntivato €',
            field: 'finalAmount',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.finalAmount);
            },
            type: 'rightAligned',
            minWidth: 280,
         },

         {
            headerName: 'Importo Quota €',
            field: 'amountQ',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.amountQ);
            },
            type: 'rightAligned',
            minWidth: 200,
         },
         {
            headerName: 'Importo Quota Rendicontabile €',
            field: 'reportableAmountQ',
            filter: 'agTextColumnFilter',
            valueGetter: (params) => {
               return formatToDecimals(params.data.reportableAmountQ);
            },
            type: 'rightAligned',
            minWidth: 300,
         },
         {
            headerName: 'Data Quota',
            field: 'dueDateQ',
            valueGetter: (params) => {
               if (params.data.dueDateQ) {
                  return dateFormatter(params.data.dueDateQ);
               } else return;
            },
            filter: 'agTextColumnFilter',
            ...columnCentered(),
            minWidth: 200,
         },
      ]);
   }, []);

   return (
      <div>
         {modalIsVisible && (
            <Modal
               size='xl'
               className='modal-with-nav'
               show={modalIsVisible}
               onHide={() => handleCloseModal(setModalIsVisible)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Fattura {Number(userRole) === 1 || Number(userRole) === 5 ? detail?.id : ''}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {spinnerVisible ? (
                     <Row>
                        <Col className='d-flex justify-content-center align-items-center'>
                           <Spinner animation='border' role='status' variant='dark'>
                              <span className='visually-hidden'>Loading...</span>
                           </Spinner>
                        </Col>
                     </Row>
                  ) : (
                     <Tabs
                        className='mb-4'
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k)}
                        fill>
                        {/* Anagrafica */}
                        <Tab
                           eventKey='registry'
                           title={'Dati Fattura'}
                        >
                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Stream</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.stream?.streamId
                                       ? `${detail?.stream?.streamId} - ${detail?.stream?.name}`
                                       : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Voce di Spesa</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.projectExpenseItem?.noticeExpenseItem?.name ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Sottovoce di Spesa</Form.Label>
                                 <Form.Text as='p'>{detail?.projectExpenseItem?.name ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>CUP Progetto</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.project?.cup ?? (detail?.stream?.project?.cup ?? 'N.D.')}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>CIG</Form.Label>
                                 <Form.Text as='p'>{detail?.tenderCig?.cig ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Gara</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.tenderCig ? detail?.tenderCig?.tender?.tenderNumber : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Contratto Dedicato</Form.Label>
                                 <Form.Text as='p'> {detail?.contract?.po === false ? detail?.contract?.number : 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Contratto Quadro</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.contract?.po === true && detail?.contract?.frameworkContract
                                       ? detail?.contract?.frameworkContract.number
                                       : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. ODA/LCL</Form.Label>
                                 <Form.Text as='p'>{detail?.contract?.po === true ? detail?.contract?.number : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nr. Fattura</Form.Label>
                                 <Form.Text as='p'>{detail?.number ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>ID Fattura</Form.Label>
                                 <Form.Text as='p'>{detail?.rpCode ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Data Fattura</Form.Label>
                                 <Form.Text as='p'>{detail?.date ? dateFormatter(detail?.date) : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Fattispecie RSI</Form.Label>
                                 <Form.Text as='p'>{detail?.rdiTypeCode ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Imponibile (€)</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.taxableAmount
                                       ? formatToDecimals(detail?.taxableAmount)
                                       : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Ritenuta D'Acconto (€)</Form.Label>
                                 <Form.Text as='p'> {detail?.withholding ? formatToDecimals(detail?.withholding) : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Contributi Cassa (€)</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.pensionFundContribution
                                       ? formatToDecimals(detail?.pensionFundContribution)
                                       : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Spese di Trasporto (€)</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.shippingFee ? formatToDecimals(detail?.shippingFee) : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Iva (€)</Form.Label>
                                 <Form.Text as='p'>{detail?.vatAmount ? formatToDecimals(detail?.vatAmount) : 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Totale Fattura (€)</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.totalAmount ? formatToDecimals(detail?.totalAmount) : 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Codice Fornitore</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.supplierCode ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>P.Iva Fornitore</Form.Label>
                                 <Form.Text as='p'>{detail?.supplierVat ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col}>
                                 <Form.Label>Nome Fornitore</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.supplierName ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Form.Group as={Col}>
                                 <Form.Label>Classifica Regis</Form.Label>
                                 <Form.Text as='p'>
                                    {detail?.regisTaxonomy ?? 'N.D.'}
                                 </Form.Text>
                              </Form.Group>
                              <Col/>
                           </Row>

                           <Row className='mb-2rem'>
                              <h2>Pagamento</h2>

                              <Form.Group as={Col} className='px-4'>
                                 <Form.Label className='mb-3'>CUP</Form.Label>
                                 <Form.Text as='p'>{detail?.paymentCup ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='px-4'>
                                 <Form.Label className='mb-3'>CIG</Form.Label>
                                 <Form.Text as='p'>{detail?.paymentCig ?? 'N.D.'}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='px-4'>
                                 <Form.Label className='mb-3'>CRO</Form.Label>
                                 <Form.Text as='p'>{detail?.payment?.cro ?? 'N.D.'}</Form.Text>
                              </Form.Group>
                           </Row>

                           {userRole < 70 && (             
                              <Row className="flex-column">
                                 <Col><h2>Posizioni</h2></Col>
                                 <Col>
                                    {rowData && rowData.length > 0 ? (
                                       <OESimpleTable
                                          columnDefs={columnDef}
                                          rowData={rowData}
                                          buttonToolbarVisible={false}
                                          pageSizeId={'payment_invoice_quotas'}
                                       />
                                    ) : (
                                       <Row className='text-center my-5'>
                                          <span>Non sono presenti posizioni.</span>
                                       </Row>
                                    )}
                                 </Col>
                              </Row>
                           )}   

                           {sbShow}
                        </Tab>

                        {/* Documenti */}
                        <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                           {detail.id && <UploadDocument entity='invoices' entityId={detail.id} externalBorder={true} title='Documenti correlati' />}

                           {detail?.paymentId &&
                              <UploadDocument entity='payments' entityId={detail?.paymentId} disableDelete={true} title='Giustificativi di pagamento' />
                           }
                        </Tab>

                        {/* CheckLists */}
                        {userRole < 70 && (
                           <Tab eventKey='checklist-invoices' title={'Checklist Fatture'}>
                              {detail.id && (
                                 <CheckList
                                    entityType='invoices'
                                    entityId={data.id}
                                    disabled={detail?.reported || detail?.reporting}
                                 />
                              )}
                           </Tab>
                        )}

                        {userRole < 70 && (
                           <Tab eventKey='checklist-invoicePayments' title={'Checklist Pagamenti'}>
                              {detail.payments?.length > 0 ? (
                                 <CheckList
                                    entityType='invoicePayments'
                                    entityId={detail?.payments?.[0]?.pivot?.id}
                                    disabled={detail?.reported || detail?.reporting}
                                 />
                              ) : (
                                 <div className='text-center mt-4'><span>Nessun pagamento associato a questo elemento</span></div>
                              )}
                           </Tab>
                        )}
                     </Tabs>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setModalIsVisible)}>
                     Chiudi
                  </Button>
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default InvoiceDetailSummaryModal;
