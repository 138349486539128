import React, { useState, useEffect, Fragment, useContext, useCallback } from 'react';
import {
   Button,
   OverlayTrigger,
   Tooltip,
   Modal,
   Form,
   Row,
   Col,
   Tab,
   Tabs,
   Spinner,
   Accordion,
} from 'react-bootstrap';
import { Lens, IcoPlus, IcoMinusCircled } from '@icons';
import UploadDocument from 'components/form/upload/uploadDocuments';
import CheckList from 'components/checklist/checkList';
import { dateFormatterInput } from '@lib/helpers/formatting';
import { LevelContext } from 'components/contexts/LevelContext';
import OeApiResponseRenderer from 'components/OeApiResponseRenderer';
import {
   editFrameworkContract,
   getAllInvolvedSubjectsByFrameworkContract,
   createInvolvedSubject,
   editInvolvedSubject,
   deleteInvolvedSubject,
} from '@lib/api/index';
import Select from 'react-select';
import { translate } from 'components/translation/translation';
import { OETrans } from 'components/translation/OETrans';

const ContractDetailModal = ({ data, optionsSubjectRole, setRefresh }) => {
   const { isOe, userRole } = useContext(LevelContext);
   const [detail, setDetail] = useState({});
   const [involvedSubjects, setInvolvedSubjects] = useState([]);
   const [originInvolvedSubjects, setOriginInvolvedSubjects] = useState([]);
   const [showModalEdit, setShowModalEdit] = useState(false);
   const [sbShow, setSbShow] = useState(<></>);
   const [activeKey, setActiveKey] = useState('registry');
   const [isDisabled, setIsDisabled] = useState(false);
   const [optionsSubjectRoles, setOptionsSubjectRoles] = useState([]);
   const [selectedSubjectRole, setSelectedSubjectRole] = useState(null);
   const [isEditing, setIsEditing] = useState(false);
   //console.log('detail', detail);
   //console.log('involvedSubjects', involvedSubjects);

   const fetchData = useCallback(async () => {
      getAllInvolvedSubjectsByFrameworkContract(data.id).then((retrievedSubjects) => {
         if (retrievedSubjects.success) {
            setInvolvedSubjects(retrievedSubjects.data);
            setOriginInvolvedSubjects(retrievedSubjects.data);
         }
      });
   }, [data.id]);

   useEffect(() => {
      setDetail(data);
      setIsDisabled(data.reported);

      if (data.id) {
         fetchData()
      }
   }, [data, fetchData]);

   useEffect(() => {
      setDetail(data);
      setIsDisabled(data.reported);

      if (data.id) {
         getAllInvolvedSubjectsByFrameworkContract(data.id).then((retrievedSubjects) => {
            if (retrievedSubjects.success) {
               setInvolvedSubjects(retrievedSubjects.data);
               setOriginInvolvedSubjects(retrievedSubjects.data);
            }
         });
      }
   }, [data]);

   useEffect(() => {
      if (optionsSubjectRole) {
         setOptionsSubjectRoles(optionsSubjectRole);
      }
   }, [optionsSubjectRole]);

   useEffect(() => {
      if (data?.subjectRole && optionsSubjectRoles.length > 0) {
         setSelectedSubjectRole(
            ...optionsSubjectRoles.filter((option) => option.value === data?.subjectRole)
         );
      }
   }, [data, optionsSubjectRoles]);

   const handleStatusBar = (dataToHandle) => {
      setSbShow(<OeApiResponseRenderer dataToHandle={dataToHandle} />);
   };

   const handleCloseModal = (setShowModal) => {
      setSbShow(<></>);
      setRefresh(true);
      setShowModal(false);
   };
   const onChange = (e, fieldName, setSelect, elIndex, fieldNameSubject, involvedSubjects) => {
      //console.log('e onChange:', e);
      let tempContract = { ...detail };
      let value;

      switch (fieldName) {
         case 'date':
         case 'lifetime':
         case 'extension':
         case 'countryCode':
         case 'vatNumber':
         case 'dl772021':
            value = e.target.value ? e.target.value : null;
            tempContract[fieldName] = value;
            break;

         case 'subjectRole':
            value = e ? e.value : null;
            tempContract[fieldName] = value;

            setSelect(e ?? []);
            break;
         case 'checkSignatures':
            value = e.target.checked;
            tempContract[fieldName] = value;
            break;
         case 'involvedSubjects':
            value = e ? (e.target ? e.target.value : e.value) : null;
            let tempInvolvedSubjects = [...involvedSubjects];

            let tempElement = {
               ...tempInvolvedSubjects[elIndex],
               [fieldNameSubject]: value,
               frameworkContractId: detail.id,
            };

            tempInvolvedSubjects[elIndex] = tempElement;

            setInvolvedSubjects(tempInvolvedSubjects);
            break;
         default:
            value = e.target.value;
            tempContract[fieldName] = value;
      }

      setDetail(tempContract);
   };

   const handleShowModal = (setShowModal) => setShowModal(true);

   const editTooltip = (props) => <Tooltip {...props}>Dettaglio</Tooltip>;

   const handleEdit = async (e) => {
      e.preventDefault();
      setSbShow(<></>);
      setIsEditing(true);
      const {
         checklist,
         checkliststatus,
         cigs,
         createdAt,
         createdBy,
         deleted,
         href,
         id,
         number,
         reported,
         tender,
         tenderId,
         updatedAt,
         updatedBy,
         ...restDetail
      } = detail;

      const editResult = await editFrameworkContract(tenderId, id, restDetail);
      if (editResult.success) {
         handleStatusBar(editResult);
      } else {
         handleStatusBar(editResult);
      }
      setIsEditing(false);
   };

   const handleEditSubjects = async (e) => {
      e.preventDefault();
      setSbShow(<></>);
      setIsEditing(true);

      let resultOfActions = [true];

      let [subjectsResults] = await Promise.all([
         handleItemList(
            "involvedSubjects",
            originInvolvedSubjects,
            involvedSubjects,
            deleteInvolvedSubject,
            editInvolvedSubject,
            createInvolvedSubject,
            detail.id
         ),
      ]);
      //console.log('subjectsResults', subjectsResults);

      const resultOfAll = resultOfActions.concat(subjectsResults.resultOfActions);
      //console.log('resultOfAll', resultOfAll);

      if (resultOfAll.length > 0 && resultOfAll.every((el) => el === true)) {
         setTimeout(() => {
            fetchData();
         }, 500);

         handleStatusBar({
            success: true,
            message: translate('common.message.edited', 'Elemento modificato con successo'),
         });
      } else {
         handleStatusBar(subjectsResults.messageResult);
      }

      setIsEditing(false);
   };

   const handleItemList = async (
      entityType,
      originItemList,
      itemList,
      deleteAPI,
      editAPI,
      createAPI,
      entityId
   ) => {
      let resultOfActions = [];

      const oldItemsDeleted = originItemList.filter((object1) => {
         return !itemList.some((object2) => {
            return object1.id === object2.id;
         });
      });

      if (oldItemsDeleted.length > 0) {
         oldItemsDeleted.forEach(async (item) => {
            const deleteResult = await deleteAPI(entityId, item.id);
            resultOfActions.push(deleteResult.success);
         });
      }

      let validationFields = [];

      switch (entityType) {
         case "involvedSubjects":
            validationFields = ["vatNumber"];
            break;
         default:
            validationFields = [];
      }

      let result;
      let messageResult;

      await Promise.all(
         itemList.map(async (item) => {
            const resultOfEvery = validationFields.every((field) => item[field]);
            //console.log('resultOfEvery', resultOfEvery);

            if (resultOfEvery) {
               if (item.id) {
                  const { createdAt, createdBy, updatedAt, updatedBy, ...restItem } = item;
                  result = await editAPI(entityId, restItem);
                  //console.log('result edit:', result);
                  if (result.success === false) messageResult = result;
                  resultOfActions.push(result.success);
               } else {
                  item.tenderId = entityId;
                  result = await createAPI(entityId, item);
                  //console.log('result create:', result);
                  if (result.success === false) messageResult = result;
                  resultOfActions.push(result.success);
               }
            } else {
               messageResult = {
                  success: false,
                  data: { validation: [translate('common.error.fillfields', 'Azione non eseguita. Riempi tutti i campi')] },
               };
               resultOfActions.push(false);
               return;
            }
         })
      );

      //console.log('messageResult:', messageResult);
      return { resultOfActions, messageResult };
   };

   const addElement = (typeArray, setTypeArray) => {
      let tempInvolvedSubjects = [...typeArray];

      tempInvolvedSubjects.push({
         frameworkContractId: detail.id,
         role: '',
         name: '',
         vatNumber: '',
         countryCode: '',
         subscriptionDate: '',
         validityPeriod: '',
         dl772021: ''
      });

      setTypeArray(tempInvolvedSubjects);
   };

   const removeElement = (typeArray, setTypeArray, index) => {
      let tempTypeArray = [...typeArray];

      tempTypeArray.splice(index, 1);

      setTypeArray(tempTypeArray);
   };

   const involvedSubjectsRows = (involvedSubjects, setInvolvedSubjects) => {
      let rows = [];

      if (involvedSubjects) {
         if (involvedSubjects.length === 0) {
            involvedSubjects = [{
               frameworkContractId: detail.id,
               role: '',
               name: '',
               vatNumber: '',
               countryCode: '',
               subscriptionDate: '',
               validityPeriod: '',
               dl772021: ''
            }];
         }

         let lastIndex = involvedSubjects.length - 1;

         rows = involvedSubjects.map((el, i) => {
            const isThisRowFilled = el.vatNumber ? true : false;

            return (<Fragment key={"involvedSubjects-" + i}>
               <Form.Label className='fw-bold'>
                  <span className='align-middle'><OETrans code='common.subject' fallback={'Soggetto'} />&nbsp;{i + 1}</span>
                  {involvedSubjects.length !== 1 ? (
                     <Button
                        variant="link"
                        className='ms-1'
                        onClick={() => removeElement(involvedSubjects, setInvolvedSubjects, i)}
                     >
                        <IcoMinusCircled className="red-color ico-small indicators" />
                     </Button>
                  ) : null}
               </Form.Label>

               <Row style={{ marginBottom: '40px' }}>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.role' fallback={'Ruolo'} /></Form.Label>
                        <Select
                           components={{ IndicatorSeparator: () => null, }}
                           className="react-select-container"
                           classNamePrefix="react-select"
                           name='role'
                           placeholder={translate('common.placeholder.select', 'Seleziona...')}
                           value={optionsSubjectRoles.filter(opt => opt.value === el.role)}
                           options={optionsSubjectRoles}
                           onChange={(e) => onChange(e, 'involvedSubjects', undefined, i, 'role', involvedSubjects)}
                           isDisabled={isDisabled}
                           isClearable
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.name' fallback={'Nome'} /></Form.Label>
                        <Form.Control
                           type='text'
                           name='name'
                           value={el.name ?? ""}
                           onChange={e => onChange(e, 'involvedSubjects', undefined, i, 'name', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label>
                           <OETrans code='involvedsubjects.vatnumber' fallback={'Partita IVA'} />
                           &nbsp;<span className="required-field">*</span>
                        </Form.Label>
                        <Form.Control
                           type="text"
                           name="vatNumber"
                           value={el.vatNumber ?? ""}
                           onChange={(e) => onChange(e, "involvedSubjects", undefined, i, 'vatNumber', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row style={{ marginBottom: '40px' }}>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.countrycode' fallback={'Prefisso Internazionale'} /></Form.Label>
                        <Form.Control
                           type="text"
                           name="countryCode"
                           value={el.countryCode ?? ''}
                           onChange={e => onChange(e, "involvedSubjects", undefined, i, 'countryCode', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.subscriptiondate' fallback={'Data di sottoscrizione'} /></Form.Label>
                        <Form.Control
                           type="date"
                           name="subscriptionDate"
                           value={el.subscriptionDate ? dateFormatterInput(el.subscriptionDate) : ""}
                           onChange={e => onChange(e, "involvedSubjects", undefined, i, 'subscriptionDate', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.validityperiod' fallback={'Periodo di validità'} /></Form.Label>
                        <Form.Control
                           type="text"
                           name="validityPeriod"
                           value={el.validityPeriod ?? ''}
                           onChange={e => onChange(e, "involvedSubjects", undefined, i, 'validityPeriod', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
                  <Col>
                     <Form.Group as={Col}>
                        <Form.Label><OETrans code='involvedsubjects.dl772021' fallback={'D.L. 77/2021'} /></Form.Label>
                        <Form.Control
                           type="text"
                           name="dl772021"
                           value={el.dl772021 ?? ''}
                           onChange={e => onChange(e, "involvedSubjects", undefined, i, 'dl772021', involvedSubjects)}
                           disabled={isDisabled}
                        />
                     </Form.Group>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     {i === lastIndex ? (
                        <Button
                           variant="primary"
                           className="me-3"
                           onClick={() => addElement(involvedSubjects, setInvolvedSubjects)}
                           disabled={!isThisRowFilled && !el.vatNumber}
                        >
                           <IcoPlus className="light-color ico-small plus new-button" />
                           <span className="d-md-inline"><OETrans code='common.involvedsubjects.addsubject' fallback={'Aggiungi soggetto'} /></span>
                        </Button>
                     ) : null}
                  </Col>
               </Row>
            </Fragment>
            );
         });
      }

      return rows;
   };

   return (
      <div>
         <OverlayTrigger placement='top' overlay={editTooltip}>
            <Button variant='link' onClick={() => handleShowModal(setShowModalEdit)}>
               <Lens className='second-color path ico-small edit' />
            </Button>
         </OverlayTrigger>

         {showModalEdit && (
            <Modal
               fullscreen
               className='modal-with-nav'
               show={showModalEdit}
               onHide={() => handleCloseModal(setShowModalEdit)}
               centered>
               <Modal.Header closeButton>
                  <Modal.Title>Dettaglio Contratto</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <Tabs className='mb-4' activeKey={activeKey} onSelect={(k) => setActiveKey(k)} fill>
                     {/* Anagrafica */}
                     <Tab eventKey='registry' title={'Dati Contratto'}>
                        <Form onSubmit={handleEdit} id={`form-edit-cq-${detail?.number}`}>
                           <Row style={{ marginBottom: '40px' }}>
                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>ID Gara</Form.Label>
                                 <Form.Text as='p'>{detail.tender?.tenderNumber ?? ''}</Form.Text>
                              </Form.Group>

                              <Form.Group as={Col} className='col-4'>
                                 <Form.Label>CIG</Form.Label>
                                 <Form.Text as='p'>
                                    {detail.cigs?.map((cig) => (
                                       <Fragment key={cig.id}>
                                          {cig.cig}
                                          <br />
                                       </Fragment>
                                    )) ?? 'N.D'}
                                 </Form.Text>
                              </Form.Group>

                              <Form.Group as={Col}>
                                 <Form.Label>ID Contratto</Form.Label>
                                 <Form.Text as='p'>{detail.number ?? 'N.D'}</Form.Text>
                              </Form.Group>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label>Data Sottoscrizione</Form.Label>
                                    <Form.Control
                                       type='date'
                                       name='date'
                                       value={dateFormatterInput(detail?.date) || ''}
                                       onChange={(e) => onChange(e, 'date')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>

                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label >Periodo di Vigenza</Form.Label>
                                    <Form.Control
                                       type='text'
                                       name='lifetime'
                                       value={detail?.lifetime ?? ''}
                                       onChange={(e) => onChange(e, 'lifetime')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label>Estensione</Form.Label>
                                    <Form.Control
                                       type='text'
                                       name='extension'
                                       value={detail?.extension ?? ''}
                                       onChange={(e) => onChange(e, 'extension')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label>Codice Nazione</Form.Label>
                                    <Form.Control
                                       type='text'
                                       name='countryCode'
                                       value={detail?.countryCode ?? ''}
                                       onChange={(e) => onChange(e, 'countryCode')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label>Codice Fiscale - P.IVA Soggetto</Form.Label>
                                    <Form.Control
                                       type='text'
                                       name='vatNumber'
                                       value={detail?.vatNumber ?? ''}
                                       onChange={(e) => onChange(e, 'vatNumber')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label className='mb-3'>Ruolo Soggetto</Form.Label>
                                    <Select
                                       components={{
                                          IndicatorSeparator: () => null,
                                       }}
                                       className="react-select-container"
                                       classNamePrefix="react-select"
                                       name='subjectRole'
                                       placeholder={translate('common.placeholder.select', 'Seleziona...')}
                                       value={selectedSubjectRole}
                                       options={optionsSubjectRoles}
                                       onChange={(e) => onChange(e, 'subjectRole', setSelectedSubjectRole)}
                                       isDisabled={isDisabled}
                                       isClearable
                                    />
                                 </Form.Group>
                              </Col>
                           </Row>

                           <Row style={{ marginBottom: '40px' }}>
                              <Col className='col-4'>
                                 <Form.Group as={Col}>
                                    <Form.Label>Presenza delle Firme</Form.Label>
                                    <Form.Check
                                       // className='text-center'
                                       type='checkbox'
                                       name='checkSignatures'
                                       defaultChecked={detail?.checkSignatures}
                                       onClick={(e) => onChange(e, 'checkSignatures')}
                                       disabled={isDisabled}
                                    />
                                 </Form.Group>
                              </Col>
                           </Row>
                        </Form>
                        {sbShow}
                     </Tab>

                     {/* Soggetti coinvolti */}
                     <Tab eventKey='subjects' title={<OETrans code='common.subjectsinvolved' fallback={'Soggetti coinvolti'} />}>
                        <Form onSubmit={handleEditSubjects} id={`form-edit-cq-${detail?.number}-subjects`}>
                           {detail.id && involvedSubjectsRows(involvedSubjects, setInvolvedSubjects)}
                        </Form>

                        {sbShow}
                     </Tab>

                     {/* Documenti */}
                     <Tab eventKey='docs' title={<OETrans code='common.documents' fallback={'Documenti'} />}>
                        <UploadDocument entity='frameworkContracts' entityId={detail.id} />
                        
                        {involvedSubjects.length > 0 &&
                           <Row>
                              <span className="mb-2rem">Documenti altri soggetti</span>
                              {involvedSubjects.map((subj, index) => {
                                 return <Fragment key={"docs-involvedSubjects-" + index}>
                                    <Accordion defaultActiveKey="0">
                                       <Accordion.Item eventKey={index}>
                                          <Accordion.Header>
                                             <span className='align-middle'><OETrans code='common.subject' fallback={'Soggetto'} />&nbsp;{index + 1}</span>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                             <UploadDocument entity='involvedSubjects' entityId={subj.id} title='' />
                                          </Accordion.Body>
                                       </Accordion.Item>
                                    </Accordion>
                                 </Fragment>
                              })}
                           </Row>
                        }
                     </Tab>

                     {/* CheckLists */}
                     {userRole < 70 && (
                        <Tab eventKey='checklist' title={'Checklist'}>
                           <CheckList
                              entityType='frameworkContracts'
                              entityId={detail.id}
                              disabled={detail?.reported}
                           />
                        </Tab>
                     )}
                  </Tabs>

                  {(activeKey === "subjects") && (
                     <Row className='mt-5'>
                        <Col className="col-12">
                           <span className="label">
                              <span className="required-field">*</span>&nbsp;<OETrans code='common.requiredFields' fallback={'Campi obbligatori'} />
                           </span>
                        </Col>
                     </Row>
                  )}
               </Modal.Body>

               <Modal.Footer>
                  <Button variant='secondary' onClick={() => handleCloseModal(setShowModalEdit)}>
                     Chiudi
                  </Button>
                  {activeKey === 'checklist' && (isOe || userRole === 5) ? (
                     <Button
                        disabled={detail?.reported}
                        type='submit'
                        form='checklist-frameworkContracts'
                        variant='primary'>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                     </Button>
                  ) : activeKey === 'registry' && (isOe || userRole === 5) ? (
                     <Button
                        disabled={isDisabled || isEditing}
                        type='submit'
                        form={`form-edit-cq-${detail?.number}`}
                        variant='primary'>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                        {isEditing && (
                           <Spinner
                              as='span'
                              className='ms-2'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                           />
                        )}
                     </Button>
                  ) : activeKey === 'subjects' && (isOe || userRole === 5) ? (
                     <Button
                        disabled={isDisabled || isEditing}
                        type='submit'
                        form={`form-edit-cq-${detail?.number}-subjects`}
                        variant='primary'>
                        <OETrans code='common.edit' fallback={'Modifica'} />
                        {isEditing && (
                           <Spinner
                              as='span'
                              className='ms-2'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                           />
                        )}
                     </Button>
                  ) : null}
               </Modal.Footer>
            </Modal>
         )}
      </div>
   );
};
export default ContractDetailModal;
